export default () => {
  window.addEventListener(
    "scroll",
    function () {
      const $header = document.getElementById("header-top");
      const bodyScrollTop = this.pageYOffset;

      bodyScrollTop > 40
        ? $header.classList.add("scroll")
        : $header.classList.remove("scroll");
    },
    false
  );

  // lazy loading images
  const lazyLoad = new LazyLoad({
    elements_selector: ".img-lazy",
  });

  // swipebox (images lightbox plugin)
  $(document).swipebox({
    selector: ".img-lightbox",
  });

  // twentytwenty plugin (page: our works)
  if ($(".twentytwenty-container").length) {
    $(".twentytwenty-container").twentytwenty({
      defaultOffsetPct: 0.1,
    });
  }
};
