export default () => {
    const $triggerBtn = document.querySelector('.btn-contact-doctor');
    const $close = $triggerBtn.querySelector('.btn-close');

    // close button
    $close.addEventListener('click', e => {
        e.stopPropagation();
        $triggerBtn.remove();
    });
};
