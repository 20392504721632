import "./jquery.swipebox";

import common from "./modules/_common";
import carousels from "./modules/_carousels";
import modals from "./modules/_modals";
import contactDoctorPopup from "./modules/_contact-doctor-popup";

/* jQuery */
$(function () {
  // calling modules
  common();
  carousels();
  modals();
  contactDoctorPopup();
});
