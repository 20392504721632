export default () => {
    // multiple modals
    $('.modal').on('shown.bs.modal',
        () => $('body').addClass('modal-open'));

    // contact doctor modal
    $('[href="#modal-contact-doctor"]').on('click', function () {
        window.selectedDoctor = $(this).data('doctor');
    });

    $('#modal-contact-doctor').on('shown.bs.modal', function () {
        $(this).find('.control-doctor-name').val(window.selectedDoctor);
    });
};
